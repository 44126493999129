// Core
import { isRouteErrorResponse, Navigate, useRouteError } from 'react-router-dom'

// App
import { Button } from '@src/components/ui/button'
import Err500Image from '@src/assets/images/error/err500.png'

// Internal
import './RouterErrorBoundary.css'
import { BASE_PATHS } from '@src/constants/path'

// Component
const RouterErrorBoundary = () => {
  const error = useRouteError()
  const isRouteError = isRouteErrorResponse(error)

  // Methods
  // Handle click
  const handleClick = () => {
    window.location.href = '/'
  }

  // Template
  if (isRouteError && error.status === 404) {
    return <Navigate to={BASE_PATHS.authSignIn} replace />
  }

  return (
    <div className='w-screen h-screen flex justify-center items-center py-10'>
      <div className='app-container'>
        <div className='flex flex-col lg:flex-row justify-center items-center gap-10 lg:gap-20'>
          <img src={Err500Image} className='w-1/2 lg:w-1/3' />
          <div className='flex flex-col items-center gap-4'>
            <h2 className='text-app-blue font-bold text-2xl'>Oops! Có lỗi xảy ra</h2>
            <Button onClick={handleClick}>Trở về trang chủ</Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RouterErrorBoundary
