// Core
import { Navigate, RouteObject } from 'react-router-dom'

// App
import App from '@src/app'
import { AppRouterErrorBoundary } from '@src/components'

// Internal
import authRoutes from '../pages/authentication/lib/routes'
import userRoutes from '../pages/user/lib/routes'
import adminRoutes from '../pages/admin/lib/routes'
import { BASE_PATHS } from '@src/constants/path'

// Deploy: Portal
const portalRoutesList = [
  {
    element: <Navigate to={BASE_PATHS.portal} replace />,
    index: true
  },
  userRoutes
]

// Deploy: Admin
const adminRoutesList = [
  {
    element: <Navigate to={BASE_PATHS.admin} replace />,
    index: true
  },
  adminRoutes
]

// Routes
const appRoutes: RouteObject[] = [
  {
    path: '/',
    element: <App />,
    ErrorBoundary: AppRouterErrorBoundary,
    children: [
      authRoutes,
      // Deploy: Portal
      ...portalRoutesList,
      // Deploy: Admin
      ...adminRoutesList
    ]
  }
]

export default appRoutes
