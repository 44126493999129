import { MenuItem } from '@src/app/pages/user/utils/Menu'
import { create } from 'zustand'

interface CategoriesState {
  categoriesMenu: MenuItem[]
  setCategoriesMenu: (menu: MenuItem[]) => void
}

export const useCategoriesStore = create<CategoriesState>((set) => ({
  categoriesMenu: [],
  setCategoriesMenu: (menu) => set({ categoriesMenu: menu })
}))
