import { BASE_PATHS } from '@src/constants/path'
import { RouteObject } from 'react-router-dom'

// Routes
const userRoutes: RouteObject = {
  path: BASE_PATHS.portal,
  lazy: async () => {
    const { default: Component } = await import('@src/app/pages/user')
    return { element: <Component /> }
  },
  children: [
    {
      index: true,
      lazy: async () => {
        const { default: Component } = await import('@src/app/pages/user/pages/home')
        return { element: <Component /> }
      }
    },
    {
      path: BASE_PATHS.portalUserProfile,
      lazy: async () => {
        const { default: Component } = await import('@src/app/pages/user/pages/user/profile')
        return { element: <Component /> }
      }
    },
    {
      path: BASE_PATHS.portalUserActivity,
      lazy: async () => {
        const { default: Component } = await import('@src/app/pages/user/pages/user/activity')
        return { element: <Component /> }
      }
    },
    {
      path: BASE_PATHS.portalUserStatistics,
      lazy: async () => {
        const { default: Component } = await import('@src/app/pages/user/pages/user/statistics')
        return { element: <Component /> }
      }
    },
    {
      path: BASE_PATHS.portalActivities,
      lazy: async () => {
        const { default: Component } = await import('@src/app/pages/user/pages/activities/activities-list')
        return { element: <Component /> }
      }
    },
    {
      path: BASE_PATHS.portalActivities,
      lazy: async () => {
        const { default: Component } = await import('@src/app/pages/user/pages/activities/activities-list')
        return { element: <Component /> }
      }
    },
    {
      path: BASE_PATHS.portalActivityDetailId,
      lazy: async () => {
        const { default: Component } = await import('@src/app/pages/user/pages/activities/activity-detail')
        return { element: <Component /> }
      }
    }
  ]
}

export default userRoutes
