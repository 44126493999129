import { create } from 'zustand'
import { GetActivityDto } from '@src/api/models'

interface ActivityStore {
  activity: GetActivityDto | null
  setActivity: (activity: GetActivityDto) => void
  clearActivity: () => void
}

export const useActivityStore = create<ActivityStore>((set) => ({
  activity: null,
  setActivity: (activity) => set({ activity }),
  clearActivity: () => set({ activity: null })
}))
