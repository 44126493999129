/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * API
 * OpenAPI spec version: v1
 */
import {
  useInfiniteQuery,
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  InfiniteData,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  AuthDto,
  UserInfoDto
} from '../models'
import { customClient } from '../mutator/custom-client';
import type { ErrorType, BodyType } from '../mutator/custom-client';



type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


export const postApiAuthLogin = (
    authDto: BodyType<AuthDto>,
 options?: SecondParameter<typeof customClient>,) => {
      
      
      return customClient<void>(
      {url: `/api/Auth/login`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: authDto
    },
      options);
    }
  


export const getPostApiAuthLoginMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAuthLogin>>, TError,{data: BodyType<AuthDto>}, TContext>, request?: SecondParameter<typeof customClient>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiAuthLogin>>, TError,{data: BodyType<AuthDto>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiAuthLogin>>, {data: BodyType<AuthDto>}> = (props) => {
          const {data} = props ?? {};

          return  postApiAuthLogin(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiAuthLoginMutationResult = NonNullable<Awaited<ReturnType<typeof postApiAuthLogin>>>
    export type PostApiAuthLoginMutationBody = BodyType<AuthDto>
    export type PostApiAuthLoginMutationError = ErrorType<unknown>

    export const usePostApiAuthLogin = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiAuthLogin>>, TError,{data: BodyType<AuthDto>}, TContext>, request?: SecondParameter<typeof customClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiAuthLogin>>,
        TError,
        {data: BodyType<AuthDto>},
        TContext
      > => {

      const mutationOptions = getPostApiAuthLoginMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const getApiAuthMyInfo = (
    
 options?: SecondParameter<typeof customClient>,signal?: AbortSignal
) => {
      
      
      return customClient<void>(
      {url: `/api/Auth/myInfo`, method: 'GET', signal
    },
      options);
    }
  

export const getGetApiAuthMyInfoQueryKey = () => {
    return [`/api/Auth/myInfo`] as const;
    }

    
export const getGetApiAuthMyInfoInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getApiAuthMyInfo>>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiAuthMyInfo>>, TError, TData>>, request?: SecondParameter<typeof customClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiAuthMyInfoQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiAuthMyInfo>>> = ({ signal }) => getApiAuthMyInfo(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiAuthMyInfo>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiAuthMyInfoInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getApiAuthMyInfo>>>
export type GetApiAuthMyInfoInfiniteQueryError = ErrorType<unknown>

export const useGetApiAuthMyInfoInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getApiAuthMyInfo>>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApiAuthMyInfo>>, TError, TData>>, request?: SecondParameter<typeof customClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetApiAuthMyInfoInfiniteQueryOptions(options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetApiAuthMyInfoQueryOptions = <TData = Awaited<ReturnType<typeof getApiAuthMyInfo>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiAuthMyInfo>>, TError, TData>>, request?: SecondParameter<typeof customClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiAuthMyInfoQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiAuthMyInfo>>> = ({ signal }) => getApiAuthMyInfo(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiAuthMyInfo>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiAuthMyInfoQueryResult = NonNullable<Awaited<ReturnType<typeof getApiAuthMyInfo>>>
export type GetApiAuthMyInfoQueryError = ErrorType<unknown>

export const useGetApiAuthMyInfo = <TData = Awaited<ReturnType<typeof getApiAuthMyInfo>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiAuthMyInfo>>, TError, TData>>, request?: SecondParameter<typeof customClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetApiAuthMyInfoQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const putApiAuthMyInfo = (
    userInfoDto: BodyType<UserInfoDto>,
 options?: SecondParameter<typeof customClient>,) => {
      
      
      return customClient<void>(
      {url: `/api/Auth/myInfo`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: userInfoDto
    },
      options);
    }
  


export const getPutApiAuthMyInfoMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiAuthMyInfo>>, TError,{data: BodyType<UserInfoDto>}, TContext>, request?: SecondParameter<typeof customClient>}
): UseMutationOptions<Awaited<ReturnType<typeof putApiAuthMyInfo>>, TError,{data: BodyType<UserInfoDto>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiAuthMyInfo>>, {data: BodyType<UserInfoDto>}> = (props) => {
          const {data} = props ?? {};

          return  putApiAuthMyInfo(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutApiAuthMyInfoMutationResult = NonNullable<Awaited<ReturnType<typeof putApiAuthMyInfo>>>
    export type PutApiAuthMyInfoMutationBody = BodyType<UserInfoDto>
    export type PutApiAuthMyInfoMutationError = ErrorType<unknown>

    export const usePutApiAuthMyInfo = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiAuthMyInfo>>, TError,{data: BodyType<UserInfoDto>}, TContext>, request?: SecondParameter<typeof customClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof putApiAuthMyInfo>>,
        TError,
        {data: BodyType<UserInfoDto>},
        TContext
      > => {

      const mutationOptions = getPutApiAuthMyInfoMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const deleteApiAuthLogout = (
    
 options?: SecondParameter<typeof customClient>,) => {
      
      
      return customClient<void>(
      {url: `/api/Auth/logout`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteApiAuthLogoutMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiAuthLogout>>, TError,void, TContext>, request?: SecondParameter<typeof customClient>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteApiAuthLogout>>, TError,void, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiAuthLogout>>, void> = () => {
          

          return  deleteApiAuthLogout(requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteApiAuthLogoutMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiAuthLogout>>>
    
    export type DeleteApiAuthLogoutMutationError = ErrorType<unknown>

    export const useDeleteApiAuthLogout = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiAuthLogout>>, TError,void, TContext>, request?: SecondParameter<typeof customClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteApiAuthLogout>>,
        TError,
        void,
        TContext
      > => {

      const mutationOptions = getDeleteApiAuthLogoutMutationOptions(options);

      return useMutation(mutationOptions);
    }
    